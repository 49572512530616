/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-unused-vars */

import styled from '@emotion/styled';
import DraggableSelect from 'components/Form/Input/DraggableSelect/DraggableSelect';
import { createForm } from 'final-form';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationSettingsSelector } from 'store/settings/settings.selectors';
import { getSettingsSpinnerSelector } from 'store/spinner/spinner.selectors';
import { IAutocompleteField } from 'types/shared';
import { useTypedSelector } from 'utils/hooks/TypedSelector';
import {
  EntryGroupingType,
  HandleMultipleReferencesType,
  OrganizationSettingsDTO,
} from 'utils/restApplicationClient';
import useSettingsForm from './SettingsFormHook';
import {
  CustomMappingContainer,
  CustomMappingLegend,
} from './SettingsViewContainer.style';
import CustomSelect from '../../components/Form/Input/CustomSelect';
import CustomCheckbox from '../../components/Form/Input/CustomCheckbox';

export type SettingsFormFields = {
  allowedContentTypesIds: IAutocompleteField[];
  entryLevelLocalizationContentTypesIds: IAutocompleteField[];
  normalTranslationContentTypesIds: IAutocompleteField[];
  recreatedContentTypesIds: IAutocompleteField[];
  defaultEntryGroupingType: EntryGroupingType;
  allowUserChangeEntryGrouping: boolean;
  handleMultipleReferences: HandleMultipleReferencesType;
};

interface IProps {
  organizationSettingsList?: OrganizationSettingsDTO;
  enabledEntryLevelLocalization?: boolean;
  disable?: boolean;
  clientId?: string;
}

const defaultEntryGroupingType: Array<{ value: string; name: string }> = [
  { value: 'SEPARATELY', name: 'Do not merge files' },
  { value: 'ENTRY_GROUPING', name: 'Merge files separately by entry' },
  { value: 'ALL_IN_ONE_FILE', name: 'Merge all files in one file' },
];

const HandleMultipleReferencesMap: Array<{ value: string; name: string }> = [
  {
    value: 'REPEAT',
    name: 'Insert duplicate each time reference is repeated',
  },
  {
    value: 'DO_NOT_REPEAT',
    name: 'Only insert repeated reference on first occurrence',
  },
];

const mapOptions = (
  value: string[],
  settingsMap: IAutocompleteField[],
  filter?: string[],
  aditionalFilter?: string[],
): IAutocompleteField[] => {
  return (
    value
      .map(
        (data) =>
          settingsMap.find(({ value }) => value === data) || {
            label: '',
            value: '',
          },
      )
      .filter(({ value }) => filter?.includes(value) !== true)
      .filter(({ value }) => aditionalFilter?.includes(value) !== true) || []
  );
};

const SettingsFormContainer = ({
  enabledEntryLevelLocalization,
  disable,
  clientId,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const settingsSpinner = useSelector(getSettingsSpinnerSelector);
  const currentOrganizationSettings = useTypedSelector(
    getCurrentOrganizationSettingsSelector,
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit = (values: SettingsFormFields): void => {};

  const form = useMemo(
    () =>
      createForm({
        onSubmit,
      }),
    [],
  );

  const {
    handleAvailableTypesChange,
    handleEntryLevelChange,
    handleNormallyChange,
    handleRecreatedChange,
    handleAllowUserChangeEntryGrouping,
    handleDefaultEntryGroupingType,
    handleHandleMultipleReferences,
  } = useSettingsForm(form, currentOrganizationSettings, clientId);

  const settingsMap = useCallback(
    () =>
      currentOrganizationSettings?.availableContentTypes.map(
        ({ contentTypeId: value, contentTypeName: label }) => ({
          label,
          value,
        }),
      ) || [],
    [currentOrganizationSettings?.availableContentTypes],
  );

  const allowedSettingsMap = useMemo(() => {
    return currentOrganizationSettings
      ? mapOptions(
          currentOrganizationSettings.allowedContentTypesIds,
          settingsMap(),
        )
      : [];
  }, [currentOrganizationSettings]);

  const entryLevelContentMap = useMemo(() => {
    return currentOrganizationSettings?.entryLevelLocalizationContentTypesIds
      ? mapOptions(
          currentOrganizationSettings?.allowedContentTypesIds,
          settingsMap(),
          currentOrganizationSettings.normalTranslationContentTypesIds,
          currentOrganizationSettings.recreatedContentTypesIds,
        )
      : [];
  }, [currentOrganizationSettings]);

  const recreatedContentMap = useMemo(() => {
    return currentOrganizationSettings?.recreatedContentTypesIds
      ? mapOptions(
          currentOrganizationSettings?.allowedContentTypesIds,
          settingsMap(),
          currentOrganizationSettings.normalTranslationContentTypesIds,
          currentOrganizationSettings.entryLevelLocalizationContentTypesIds,
        )
      : [];
  }, [currentOrganizationSettings]);

  const normalContentMap = useMemo(() => {
    return currentOrganizationSettings?.recreatedContentTypesIds
      ? mapOptions(
          currentOrganizationSettings?.allowedContentTypesIds,
          settingsMap(),
          currentOrganizationSettings.recreatedContentTypesIds,
          currentOrganizationSettings.entryLevelLocalizationContentTypesIds,
        )
      : [];
  }, [currentOrganizationSettings]);

  const initialValues: SettingsFormFields | undefined = useMemo(() => {
    if (!currentOrganizationSettings) {
      return undefined;
    }
    const {
      allowedContentTypesIds,
      recreatedContentTypesIds,
      entryLevelLocalizationContentTypesIds,
      normalTranslationContentTypesIds,
      defaultEntryGroupingType,
      allowUserChangeEntryGrouping,
      handleMultipleReferences,
    } = currentOrganizationSettings;

    return {
      allowedContentTypesIds: mapOptions(allowedContentTypesIds, settingsMap()),
      recreatedContentTypesIds: mapOptions(
        recreatedContentTypesIds,
        settingsMap(),
      ),
      entryLevelLocalizationContentTypesIds: mapOptions(
        entryLevelLocalizationContentTypesIds,
        settingsMap(),
      ),
      normalTranslationContentTypesIds: mapOptions(
        normalTranslationContentTypesIds,
        settingsMap(),
      ),
      defaultEntryGroupingType,
      allowUserChangeEntryGrouping,
      handleMultipleReferences,
    };
  }, [currentOrganizationSettings]);

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    form.initialize(initialValues);
  }, [initialValues]);

  const DisableCheckWrap = styled.div`
    div > * {
      opacity: ${disable ? `0.5` : `1`};
    }
  `;

  return (
    <>
      {!!currentOrganizationSettings ? (
        <DisableCheckWrap>
          <Form
            form={form}
            onSubmit={onSubmit}
            render={({ handleSubmit }): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <CustomMappingContainer>
                  <CustomMappingLegend>
                    {t('settings.contentModels')}
                  </CustomMappingLegend>
                  <Field
                    name="allowedContentTypesIds"
                    label="settings.availableTypes"
                    options={settingsMap()}
                    component={DraggableSelect}
                    multi
                    disabled={disable}
                    spinner={settingsSpinner}
                    handleOnClose={handleAvailableTypesChange}
                    handleOnChipDelete={handleAvailableTypesChange}
                  />
                </CustomMappingContainer>
                {enabledEntryLevelLocalization ? (
                  <CustomMappingContainer>
                    <CustomMappingLegend>
                      {t('settings.entryLevelSettingsTitle')}
                    </CustomMappingLegend>
                    <Field
                      name="entryLevelLocalizationContentTypesIds"
                      label="settings.entryLevelLocalizationContentTypesIds"
                      options={entryLevelContentMap}
                      component={DraggableSelect}
                      handleOnClose={handleEntryLevelChange}
                      handleOnChipDelete={handleEntryLevelChange}
                      multi
                      disabled={disable}
                      spinner={settingsSpinner}
                    />
                    <Field
                      name="normalTranslationContentTypesIds"
                      label="settings.normalTranslationContentTypesIds"
                      options={normalContentMap}
                      component={DraggableSelect}
                      handleOnClose={handleNormallyChange}
                      handleOnChipDelete={handleNormallyChange}
                      multi
                      disabled={disable}
                      spinner={settingsSpinner}
                    />
                    <Field
                      name="recreatedContentTypesIds"
                      label="settings.recreatedContentTypesIds"
                      options={recreatedContentMap}
                      component={DraggableSelect}
                      handleOnClose={handleRecreatedChange}
                      handleOnChipDelete={handleRecreatedChange}
                      multi
                      disabled={disable}
                      spinner={settingsSpinner}
                    />
                  </CustomMappingContainer>
                ) : undefined}
                <CustomMappingContainer>
                  <CustomMappingLegend>
                    {t('settings.fileMerging.tittle')}
                  </CustomMappingLegend>
                  <Field name="defaultEntryGroupingType">
                    {({ input }): JSX.Element => (
                      <CustomSelect
                        label="settings.fileMerging.defaultEntryGrouping"
                        inputProps={input}
                        error={''}
                        onChange={(event): void =>
                          handleDefaultEntryGroupingType(event.target.value)
                        }
                        rows={defaultEntryGroupingType}
                      />
                    )}
                  </Field>

                  <Field name="allowUserChangeEntryGrouping" type="checkbox">
                    {({ input }): JSX.Element => (
                      <CustomCheckbox
                        label="settings.fileMerging.allowUserChangeEntryGrouping"
                        inputProps={{
                          ...input,
                          onChange: (event): void =>
                            handleAllowUserChangeEntryGrouping(
                              event.target.checked,
                            ),
                        }}
                        testId="checkboxInput"
                      />
                    )}
                  </Field>

                  <Field name="handleMultipleReferences">
                    {({ input }): JSX.Element => (
                      <CustomSelect
                        label="settings.fileMerging.handleMultipleReferences"
                        inputProps={{ ...input }}
                        onChange={(event): void =>
                          handleHandleMultipleReferences(event.target.value)
                        }
                        error={''}
                        rows={HandleMultipleReferencesMap}
                        tooltip={true}
                        tooltipText={t(
                          'settings.fileMerging.handleMultipleReferencesTooltip',
                        )}
                      />
                    )}
                  </Field>
                </CustomMappingContainer>
              </form>
            )}
          />
        </DisableCheckWrap>
      ) : null}
    </>
  );
};

export default withTranslation()(SettingsFormContainer);

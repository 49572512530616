/* eslint-disable id-length */
import { createReducer } from '@reduxjs/toolkit';
import {
  addSpace,
  deleteOrganizationSettings,
  deleteOrganizationSettingsError,
  deleteOrganizationSettingsSuccess,
  fetchSettings,
  fetchSettingsSuccess,
  getAllSettingsSuccess,
  getSettings,
  getSettingsSuccess,
  initializationCheck,
  initializationCheckSuccess,
  initializeContentfulError,
  initializeContentfulSuccess,
  setClientId,
  setCurrentOrganizationSettings,
  updateSettings,
} from './settings.actions';
import { IConfigState } from './settings.interface';

const initState: IConfigState = {
  successes: {},
  errors: {},
  initializationCheck: false,
  SettingsDTO: [],
  allSettings: undefined,
  addSpace: false,
  clientId: undefined,
};

export const configReducer = createReducer(initState, (builder) =>
  builder
    .addCase(initializeContentfulSuccess, (state, action) => {
      state.SettingsDTO = action.payload;
    })
    .addCase(initializeContentfulError, (state, action) => {
      if (action.payload) {
        state.errors.initialization = action.payload;
      }
    })
    .addCase(initializationCheck, (state) => {
      state.initializationCheck = false;
    })
    .addCase(initializationCheckSuccess, (state, action) => {
      state.initializationCheck = action.payload;
    })
    .addCase(deleteOrganizationSettings, (state) => {
      state.successes = {};
    })
    .addCase(deleteOrganizationSettingsSuccess, (state, action) => {
      if (action.payload) {
        state.successes.deletion = action.payload;
      }
    })
    .addCase(deleteOrganizationSettingsError, (state, action) => {
      if (action.payload) {
        state.errors.deletion = action.payload;
      }
    })
    .addCase(fetchSettingsSuccess, (state, action) => {
      state.SettingsDTO = action.payload;
    })
    .addCase(fetchSettings, (state) => {
      state.SettingsDTO = [];
    })
    .addCase(getAllSettingsSuccess, (state, action) => {
      state.allSettings = action.payload;
    })
    .addCase(getSettings, (state) => {
      state.SettingsDTO = [];
    })
    .addCase(getSettingsSuccess, (state, action) => {
      state.SettingsDTO = action.payload;
    })
    .addCase(addSpace, (state, action) => {
      state.addSpace = action.payload;
    })
    .addCase(setClientId, (state, action) => {
      state.clientId = action.payload;
    })
    .addCase(setCurrentOrganizationSettings, (state, action) => {
      state.selectedOrganizationSettings = action.payload;
    })
    .addCase(updateSettings, (state, action) => {
      if (state.selectedOrganizationSettings) {
        state.selectedOrganizationSettings.allowedContentTypesIds =
          action.payload.allowedContentTypesIds;
        state.selectedOrganizationSettings.entryLevelLocalizationContentTypesIds =
          action.payload.entryLevelLocalizationContentTypesIds;
        state.selectedOrganizationSettings.normalTranslationContentTypesIds =
          action.payload.normalTranslationContentTypesIds;
        state.selectedOrganizationSettings.recreatedContentTypesIds =
          action.payload.recreatedContentTypesIds;
        state.selectedOrganizationSettings.defaultEntryGroupingType =
          action.payload.defaultEntryGroupingType;
        state.selectedOrganizationSettings.allowUserChangeEntryGrouping =
          action.payload.allowUserChangeEntryGrouping;
        state.selectedOrganizationSettings.handleMultipleReferences =
          action.payload.handleMultipleReferences;
      }
      const spaceIndex = state.SettingsDTO.findIndex(
        (settings) => settings.spaceId == action.payload.spaceId,
      );
      const updatedSettings = [...state.SettingsDTO];
      const environmentIndex = updatedSettings[
        spaceIndex
      ].organizationSettingsDTOList.findIndex(
        (organizationSettings) =>
          organizationSettings.environmentId == action.payload.environmentId,
      );
      state.SettingsDTO[spaceIndex].organizationSettingsDTOList[
        environmentIndex
      ].allowedContentTypesIds = action.payload.allowedContentTypesIds;
      state.SettingsDTO[spaceIndex].organizationSettingsDTOList[
        environmentIndex
      ].entryLevelLocalizationContentTypesIds =
        action.payload.entryLevelLocalizationContentTypesIds;
      state.SettingsDTO[spaceIndex].organizationSettingsDTOList[
        environmentIndex
      ].normalTranslationContentTypesIds =
        action.payload.normalTranslationContentTypesIds;
      state.SettingsDTO[spaceIndex].organizationSettingsDTOList[
        environmentIndex
      ].recreatedContentTypesIds = action.payload.recreatedContentTypesIds;
    }),
);

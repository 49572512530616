/* eslint-disable id-length */
import { FormApi } from 'final-form';
import { useDispatch } from 'react-redux';
import { updateSettings } from 'store/settings/settings.actions';
import { SaveOrganizationSettings } from 'store/settings/settings.interface';
import { IAutocompleteField } from 'types/shared';
import {
  EntryGroupingType,
  HandleMultipleReferencesType,
  OrganizationSettingsDTO,
} from 'utils/restApplicationClient';
import { SettingsFormFields } from './SettingsFormContainer';

const useSettingsForm = (
  form: FormApi<SettingsFormFields>,
  settings: OrganizationSettingsDTO | undefined,
  clientId?: string,
): {
  handleAvailableTypesChange: (values?: IAutocompleteField[]) => void;
  handleAllowUserChangeEntryGrouping: (value: boolean) => void;
  handleHandleMultipleReferences: (value: HandleMultipleReferencesType) => void;
  handleEntryLevelChange: (values?: IAutocompleteField[]) => void;
  handleNormallyChange: (values?: IAutocompleteField[]) => void;
  handleRecreatedChange: (values?: IAutocompleteField[]) => void;
  handleDefaultEntryGroupingType: (value: EntryGroupingType) => void;
} => {
  const dispatch = useDispatch();

  const mapAutocomplete = (
    values: IAutocompleteField[] = [],
    key: keyof IAutocompleteField,
  ): string[] => values?.map((value) => value[key]) || [];

  const updateOrganizationSettings = <T extends keyof SettingsFormFields>(
    field: T,
    value: SettingsFormFields[T],
  ): void => {
    const {
      allowedContentTypesIds,
      entryLevelLocalizationContentTypesIds,
      normalTranslationContentTypesIds,
      recreatedContentTypesIds,
      allowUserChangeEntryGrouping,
      defaultEntryGroupingType,
      handleMultipleReferences,
    }: SettingsFormFields = {
      ...form.getState().values,
      [field]: value,
    };

    if (!settings || value == null) {
      return;
    }

    const { environmentId, spaceId } = settings;

    const valueCanBeDeleted = (field: IAutocompleteField<string>[]): void => {
      field.forEach((data) => {
        const fieldCheck = allowedContentTypesIds.some(
          (element) => element.value === data.value,
        );
        if (!fieldCheck) {
          const unusedIndex = field
            .map((data) => data.value)
            .indexOf(data.value);
          field.splice(unusedIndex, 1);
        }
      });
    };

    valueCanBeDeleted(entryLevelLocalizationContentTypesIds);
    valueCanBeDeleted(normalTranslationContentTypesIds);
    valueCanBeDeleted(recreatedContentTypesIds);

    const mappedSettings: SaveOrganizationSettings = {
      allowedContentTypesIds: mapAutocomplete(allowedContentTypesIds, 'value'),
      entryLevelLocalizationContentTypesIds: mapAutocomplete(
        entryLevelLocalizationContentTypesIds,
        'value',
      ),
      normalTranslationContentTypesIds: mapAutocomplete(
        normalTranslationContentTypesIds,
        'value',
      ),
      recreatedContentTypesIds: mapAutocomplete(
        recreatedContentTypesIds,
        'value',
      ),
      allowUserChangeEntryGrouping,
      defaultEntryGroupingType,
      handleMultipleReferences,
      environmentId,
      spaceId,
      clientId,
    };

    dispatch(updateSettings(mappedSettings));
  };

  const handleAvailableTypesChange = (
    values: IAutocompleteField[] = [],
  ): void => {
    updateOrganizationSettings('allowedContentTypesIds', values);
  };

  const handleEntryLevelChange = (values: IAutocompleteField[] = []): void => {
    updateOrganizationSettings('entryLevelLocalizationContentTypesIds', values);
  };

  const handleNormallyChange = (values: IAutocompleteField[] = []): void => {
    updateOrganizationSettings('normalTranslationContentTypesIds', values);
  };

  const handleRecreatedChange = (values: IAutocompleteField[] = []): void => {
    updateOrganizationSettings('recreatedContentTypesIds', values);
  };

  const handleDefaultEntryGroupingType = (
    value: EntryGroupingType = 'SEPARATELY',
  ): void => {
    updateOrganizationSettings('defaultEntryGroupingType', value);
  };
  const handleAllowUserChangeEntryGrouping = (value = false): void => {
    updateOrganizationSettings('allowUserChangeEntryGrouping', value);
  };

  const handleHandleMultipleReferences = (
    value: HandleMultipleReferencesType = 'DO_NOT_REPEAT',
  ): void => {
    updateOrganizationSettings('handleMultipleReferences', value);
  };

  return {
    handleAvailableTypesChange,
    handleEntryLevelChange,
    handleNormallyChange,
    handleRecreatedChange,
    handleAllowUserChangeEntryGrouping,
    handleDefaultEntryGroupingType,
    handleHandleMultipleReferences,
  };
};

export default useSettingsForm;
